import React, { useEffect, useState } from "react";
import { Routes, Route, useLocation, useNavigate, redirect, Navigate } from "react-router-dom";
import axios from "axios";
import Login from "./pages/auth/Login";
import Logout from "./pages/auth/Logout";
import Home from "./pages/Home";
//import About from "./pages/About";
import Ulocells from "./pages/ulocells/Ulocells"

import Header from "./components/nav/Header";
import { PageNotFound } from "./pages/auth/PageNotFound";
//import ErrorPage from "./pages/auth/ErrorPage"
import PcrfLogs from "./pages/pcrfLogsHuawei/PcrfLogs";
import PcrfProteiPackages from "./pages/pcrfProtei/PcrfProteiPackages"
import PcrfProteiOrderedPackagesTest from "./pages/pcrfProteiTest/PcrfProteiOrderedPackagesTest"
import PcrfProteiOrderedPackages from "./pages/pcrfProtei/PcrfProteiOrderedPackages"
import PcrfProteiLogs from "./pages/pcrfProteiLogs/PcrfProteiLogs"
import SubData from "./pages/ipLook/SubData";
import CallForward from "./pages/ipLook/CallForward";
import SubsServices from "./pages/ipLook/SubsServices";
import AddKi from "./pages/ipLook/AddKi"

//import Staff from "./pages/staff/Staff";
import BorderSubs from "./pages/borderSubs/BorderSubs"
import { useAuth } from "./contexts/AuthContext"
import { jwtDecode } from "jwt-decode"


const App = () => {
  const location = useLocation();
  const navigate = useNavigate()

  const { user, setUser, setError } = useAuth()
  const [isLoading, setIsLoading] = useState(false)

  useEffect(() => {
    ////setIsLoading(true)
    if (location.pathname !== "/login" && location.pathname !== "/logout") {
      console.log("refreshing token");
      refreshToken()
    } else {
      //navigate("login")
    }
    ////setIsLoading(false)
  }, [])


  //check AT for its validity
  useEffect(() => {
   
    if (user !== null) {
      const decodedAT = (jwtDecode(user.accessToken));
      if (decodedAT.exp < new Date().getTime() / 1000) {
        refreshToken()
      }
    }

    if (location.pathname !== "/login" && location.pathname !== "/logout") {
      refreshToken()
    }


  }, [ location])




  const refreshToken = async () => {
    setIsLoading(true)
    try {
      const { data } = await axios.get("/api/refresh", { withCredentials: true })
      if (!data) {
        navigate("/login")
      }
      setUser(data)
    } catch (err) {
      //setError("Необходима авторизация!")
      setUser(null)
      navigate("/login", { state: { path: location.pathname } })
    }
    setIsLoading(false)
  }


  // {isLoading === false &&
  //if (!user) return null

  return (
    <>
      {user && <Header />}
      <Routes>
        <Route exact path="/" element={user ? <Home /> : <Login />}></Route>

        <Route exact path="/home" element={<Home />}></Route>
        <Route exact path="/login" element={<Login />}></Route>
        <Route exact path="/logout" element={<Logout />}></Route>

        <Route exact path="/ulocells" element={<Ulocells />}></Route>

        <Route exact path="/iplook-subdata" element={user?.allowedPaths?.indexOf(location.pathname) !== -1 ? <SubData /> : <Home message="Доступ запрещен" />}></Route>
        <Route exact path="/iplook-cf" element={user?.allowedPaths?.indexOf(location.pathname) !== -1 ? <CallForward /> : <Home message="Доступ запрещен" />}></Route>
        <Route exact path="/iplook-subsservices" element={user?.allowedPaths?.indexOf(location.pathname) !== -1 ? <SubsServices /> : <Home message="Доступ запрещен" />}></Route>
        <Route exact path="/iplook-ki" element={user?.allowedPaths?.indexOf(location.pathname) !== -1 ? <AddKi /> : <Home message="Доступ запрещен" />}></Route>

        <Route exact path="/border-subs" element={user?.allowedPaths?.indexOf(location.pathname) !== -1 ? <BorderSubs /> : <Home message="Доступ запрещен" />}></Route>
        <Route exact path="/pcrf-protei-ordered-packages" element={user?.allowedPaths?.indexOf(location.pathname) !== -1 ? <PcrfProteiOrderedPackages /> : <Home message="Доступ запрещен" />}></Route>
        <Route exact path="/pcrf-protei-ordered-packages-test" element={user?.allowedPaths?.indexOf(location.pathname) !== -1 ? <PcrfProteiOrderedPackagesTest /> : <Home message="Доступ запрещен" />}></Route>

        <Route exact path="/pcrf-protei-packages" element={user?.allowedPaths?.indexOf(location.pathname) !== -1 ? <PcrfProteiPackages /> : <Home message="Доступ запрещен" />}></Route>
        <Route exact path="/pcrf-protei-logs" element={user?.allowedPaths?.indexOf(location.pathname) !== -1 ? <PcrfProteiLogs /> : <Home message="Доступ запрещен" />}></Route>

        <Route exact path="/pcrf-logs" element={user?.allowedPaths?.indexOf(location.pathname) !== -1 ? <PcrfLogs /> : <Home message="Доступ запрещен" />}></Route>

        <Route path="*" element={<PageNotFound />}></Route>
      </Routes>
    </>
  );
};

export default App;
