import { useEffect } from 'react'
import { useNavigate } from 'react-router-dom';
import { useAuth } from '../../contexts/AuthContext';


const Logout = () => {
  const { logout } = useAuth()
  const navigate = useNavigate()
console.log("jhyt");


  useEffect(() => {
    logout()
    navigate('/login', {replace: true})
  }, [])
}

export default Logout

